import Vue from 'vue';
import router from '@/router';
import http from '@/http/client';

const cookieName = 'auth';

export default {
  setAuth(auth) {
    Vue.$cookies.set(cookieName, { token: auth.token, refreshToken: auth.refreshToken });
  },

  getAuth() {
    return Vue.$cookies.get(cookieName);
  },

  getToken() {
    return this.isAuthenticated() ? this.getAuth().token : null;
  },

  getRefreshToken() {
    return this.isAuthenticated() ? this.getAuth().refreshToken : null;
  },

  isAuthenticated() {
    return Vue.$cookies.isKey(cookieName);
  },

  clear() {
    Vue.$cookies.remove(cookieName);
  },

  login(username, password) {
    return http
      .post('/auth/login', { username, password })
      .then((response) => this.setAuth(response.data))
      .then(() => this.user())
    ;
  },

  user() {
    const user = Vue.prototype.$user;

    if(user) {
      return new Promise((resolve) => resolve(user));
    }

    return http.get('/me').then((response) => Vue.prototype.$user = response.data);
  },

  refresh() {
    const refreshToken = this.getRefreshToken();

    if(!refreshToken) {
      return this.logout();
    }

    return http
      .post('/auth/refresh', { refreshToken })
      .then((response) => this.setAuth(response.data))
      .catch(() => this.logout())
  },

  logout() {
    return new Promise((resolve) => {
      this.clear();

      if('login' === router.currentRoute.name) {
        return;
      }

      router.push({ name: 'login' });

      resolve();
    });
  }
}
