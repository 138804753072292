<template lang="pug">
  .spinner-border.text-primary.spinner-border-sm
    .sr-only
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'spinner-border-sm'
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
