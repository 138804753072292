// Admin panel
import 'admin-lte/plugins/bootstrap/js/bootstrap';
import 'admin-lte';
import './stylesheets/index.scss';

// Icon sets
import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faTshirt,
  faFileMedicalAlt,
  faPencilAlt,
  faTrash,
  faMicrochip,
  faChartPie,
  faAngleRight,
  faBars,
  faFileExcel,
  faWaveSquare,
  faEnvelope,
  faLock,
  faUser,
  faSignOutAlt,
  faPlus,
  faSync,
  faCheck,
  faFileContract,
  faFilePdf,
  faRunning,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
  faTshirt,
  faFileMedicalAlt,
  faPencilAlt,
  faTrash,
  faMicrochip,
  faChartPie,
  faAngleRight,
  faBars,
  faFileExcel,
  faWaveSquare,
  faEnvelope,
  faLock,
  faUser,
  faSignOutAlt,
  faPlus,
  faSync,
  faCheck,
  faFileContract,
  faFilePdf,
  faRunning,
  faExclamationTriangle
);

Vue.component('fa', FontAwesomeIcon);

// Spinners
import ScaleLoader from 'vue-spinner/src/ScaleLoader';
import Spinner from '@/components/common/Spinner';

Vue.component('button-spinner', ScaleLoader);
Vue.component('spinner', Spinner);

// Alerts
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

// Charts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
