
<template lang="pug">
  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light
    ul.navbar-nav
      li.nav-item
        a.nav-link(data-widget='pushmenu' href='#' role='button')
          fa(icon="bars")

    div.navbar-nav.ml-auto.d-flex
      .d-block(v-if="user")
        span.text-muted {{ user.fullName }}
        span.ml-2
          button.btn.btn-light(type="button" @click="logout()")
            fa(icon="sign-out-alt")

      .d-block(v-else)
        spinner
</template>

<script>
  import auth from '@/auth';

  export default {
    methods: {
      logout() {
        auth.logout();
      }
    },

    data() {
      return {
        user: null,
      }
    },

    mounted() {
      auth.user().then(user => this.user = user);
    }
  }
</script>

<i18n>
{
  "es": {
    "start": "Inicio"
  }
}
</i18n>
