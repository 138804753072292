<template lang="pug">
  .wrapper
    navbar
    left-sidebar(project-name="Bienesmart Analytics")

    .content-wrapper
      slot

    page-footer
    #sidebar-overlay(data-widget='pushmenu')
</template>

<script>
import Navbar from '@/components/application/Navbar'
import PageFooter from '@/components/application/PageFooter'
import LeftSidebar from "@/components/application/LeftSidebar";
import Breadcrumb from "@/components/common/Breadcrumb";

export default {
  components: {
    Breadcrumb,
    LeftSidebar,
    Navbar,
    PageFooter
  },

  data: function () {
    return {
      name: '',
      logo: '',
      footer: '',
      rootPath: ''
    }
  },

  mounted() {
    document.body.className = 'sidebar-mini layout-navbar-fixed';
  }
}
</script>
