
<template lang="pug">
  aside.main-sidebar.sidebar-dark-primary.elevation-4

    router-link.brand-link.elevation-4(:to="{ name: 'sessions_list' }")
      img.brand-image.img-circle.elevation-3(src="@/assets/logo.png" :alt="projectName" style='opacity: .8')
      span.brand-text.font-weight-light {{ projectName }}

    .sidebar
      nav.mt-2
        ul.nav.nav-pills.nav-sidebar.flex-column(data-widget='treeview' role='menu' data-accordion='false')
          li.nav-header {{ $t('header.menu') }}

          li.nav-item
            router-link.nav-link(:to="{ name: 'sessions_list' }")
              fa.nav-icon(icon="file-medical-alt")
              p {{ $t('menu.sessions') }}

          li.nav-header {{ $t('header.config') }}

          li.nav-item
            router-link.nav-link(:to="{ name: 'garments_list' }")
              fa.nav-icon(icon="tshirt")
              p {{ $t('menu.garment') }}

          li.nav-item
              router-link.nav-link(:to="{ name: 'activities_list' }")
                fa.nav-icon(icon="running")
                p {{ $t('menu.activity') }}
</template>

<script>
export default {
  props: {
    projectName: String,
    user: {
      type: Object,
      defaults: {}
    }
  },

  data() {
    return {}
  }
}
</script>

<i18n>
{
  "es": {
    "header": {
      "menu": "MENÚ",
      "config": "CONFIGURACIÓN"
    },
    "menu": {
      "sessions": "Sesiones",
      "garment": "Prendas",
      "activity": "Actividades"
    }
  }
}
</i18n>
