<template lang="pug">
  ol.breadcrumb.float-sm-right
    li.breadcrumb-item(v-for="crumb in crumbs")
      b(v-if="$route.path === crumb.to") {{ $t(crumb.text) }}
      router-link(:to="crumb.to" v-if="$route.path !== crumb.to") {{ $t(crumb.text) }}

</template>

<script>
    export default {
      computed: {
        crumbs: function() {
          if(0 === this.$route.matched.length) {
            return [];
          }

          let pathArray = this.$route.path.split("/")
          pathArray.shift()

          return pathArray.reduce((breadcrumbArray, path, idx) => {
            breadcrumbArray.push({
              path: path,
              to: breadcrumbArray[idx - 1]
                ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                : "/" + path,
              text: this.$route.matched[idx].name || path,
            });

            return breadcrumbArray;
          }, []);
        }
      }
    }
</script>

