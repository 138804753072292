import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/auth';

Vue.use(VueRouter)

const routes = [
  // Outside application routes
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'auth' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
  },

  // Application routes
  {
    path: '/sessions',
    redirect: '/sessions/list',
    name: 'sessions_home',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/application/Section.vue'),
    children: [
      {
        path: '/sessions/list',
        name: 'sessions_list',
        component: () => import(/* webpackChunkName: "sessions" */ '../components/sessions/SessionList.vue'),
        meta: { toolbar: true }
      },
      {
        path: '/sessions/create',
        name: 'sessions_create',
        component: () => import(/* webpackChunkName: "sessions" */ '../components/sessions/SessionEdit.vue')
      },
      {
        path: '/sessions/:session',
        name: 'sessions_edit',
        props: true,
        component: () => import(/* webpackChunkName: "sessions" */ '../components/sessions/SessionEdit.vue')
      }
    ]
  },
  {
    path: '/activities',
    redirect: '/activities/list',
    name: 'activities_home',
    component: () => import(/* webpackChunkName: "activities" */ '../views/application/Section.vue'),
    children: [
      {
        path: '/activities/list',
        name: 'activities_list',
        component: () => import(/* webpackChunkName: "activities" */ '../components/activities/ActivityList.vue'),
        meta: { toolbar: true }
      },
      {
        path: '/activities/create',
        name: 'activities_create',
        component: () => import(/* webpackChunkName: "activities" */ '../components/activities/ActivityEdit.vue')
      },
      {
        path: '/activities/:activity',
        name: 'activities_edit',
        props: true,
        component: () => import(/* webpackChunkName: "activities" */ '../components/activities/ActivityEdit.vue')
      }
    ]
  },
  {
    path: '/garments',
    redirect: '/garments/list',
    name: 'garments_home',
    component: () => import(/* webpackChunkName: "garments" */ '../views/application/Section.vue'),
    children: [
      {
        path: '/garments/list',
        name: 'garments_list',
        component: () => import(/* webpackChunkName: "garments" */ '../components/garments/GarmentsList.vue'),
        meta: { toolbar: true }
      },
      {
        path: '/garments/create',
        name: 'garments_create',
        component: () => import(/* webpackChunkName: "garments" */ '../components/garments/GarmentsEdit.vue'),
      },
      {
        path: '/garments/:garment',
        name: 'garments_edit',
        props: true,
        component: () => import(/* webpackChunkName: "garments" */ '../components/garments/GarmentsEdit.vue')
      }
    ]
  },
  {
    path: '/reports',
    name: 'reports_home',
    redirect: '/sessions/list',
    component: () => import(/* webpackChunkName: "reports" */ '../views/application/Section.vue'),
    children: [
      {
        path: '/reports/:uuid',
        name: 'report_view',
        component: () => import(/* webpackChunkName: "reports" */ '../components/report/ReportView.vue'),
        props: route => ({ uuid: route.params.uuid })
      }
    ]
  },

  // on 404 (always on bottom)
  { path: '/*', redirect: '/sessions' },
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if('login' !== to.name && !auth.isAuthenticated()) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router
