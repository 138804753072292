import axios from 'axios';
import auth from '@/auth';

//
// Defaults
//

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.timeout = 15000;

//
// Interceptors
//

axios.interceptors.request.use((config) => {
  if(null !== auth.getToken()) {
    config.headers['Authorization'] = `Bearer ${auth.getToken()}`;
  }

  return config;
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => reject(error));
    }

    // Logout user if token refresh didn't work or user is disabled
    if ('/auth/refresh' === error.config.url) {
      auth.logout();
      return new Promise((resolve, reject) => reject(error));
    }

    // Try request again with new token
    return auth.refresh()
      .then(() => {
        return new Promise((resolve, reject) => {
          axios
            .request(error.config)
            .then(response => resolve(response))
            .catch(error => reject(error))
          ;
        });
      })
      .catch((error) => Promise.reject(error));
  }
);



export default axios;
