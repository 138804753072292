<template lang="pug">
  .login-box
    .login-logo
      a(href='/')
        img(src="@/assets/logo.png")

    slot
</template>

<script>
export default {
  mounted() {
    document.body.className = 'hold-transition login-page';
  }
}
</script>
