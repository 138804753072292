<template lang="pug">
  div(:is="layout" :user="user")
    router-view
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null
    }
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'app') + '-layout';
    }
  }
}
</script>

<i18n>
{
  "es": {
    "start": "Inicio"
  }
}
</i18n>
