
<template lang="pug">
  footer.main-footer.d-flex.flex-row-reverse
    .float-right.d-none.d-sm-block
      small Proyecto BIENESMART 2
</template>

<script>
export default {
  props: [ 'text' ],

  data: function () {
    return {
      timezone: 'Madrid/Europe'
    }
  }

}
</script>
