import Vue from 'vue'
import * as Sentry from '@sentry/vue';
import { Integrations } from "@sentry/tracing";
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import App from './App.vue';
import VueCookies from 'vue-cookies';
import Vuelidate from "vuelidate";
import AppLayout from "@/views/application/AppLayout";
import AuthLayout from "@/views/auth/AuthLayout";
import DateTimeFilter from '@/filters/datetime';
import './template';

// Configure libraries
Vue.use(Vuelidate);
Vue.use(VueCookies);

// Global filters
Vue.filter('datetime', DateTimeFilter);

// Global components
Vue.component('app-layout', AppLayout);
Vue.component('auth-layout', AuthLayout);

// Global Vue parameters
Vue.config.productionTip = false;
Vue.$cookies.config('7d');

// Error tracking
Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  attachProps: true,
  integrations: [
    new Integrations.BrowserTracing()
  ]
});

// Launch
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
